@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');
body, #root{
  /* font-family: 'Poppins', sans-serif!important; */
  background: #fff!important;
  font-family: Arial, Helvetica, sans-serif!important;
  width: 100%!important;

}
/* @font-face {
  font-family: "Arial";
  src: url("public/fonts/arial.ttf");
} */

p.edit-cancel #disable:disabled {
  background: #e3dfdf !important;
  cursor: not-allowed;
}
table.table.table-bordered.hba tbody tr td:nth-child(2) input {
  
  background: #f1f0f0;
  border: none;
  padding: 4px;
}
.form-floating {
  margin-bottom: 6px!important;
}
.hba-submit-top{margin-top: 20px;}
.accordion__panel p span {
    color: #2262a7;
    font-size: 13px;
    text-align: center;
}
.error-validation {
  border-left: 3px solid red!important;
}
button.add-line-item {
  color: #fff;
  padding: 5px 15px!important;
  border: none!important;
  border-radius: 4px!important;
  margin-top: 10px!important;
  background: #e47027;
  margin-right: 8px;
}
table#rw-table thead th {
  padding-bottom: 9px!important;
}
.alerts{
  text-decoration: none!important;
}
.alerts  .alert-warning {
   
  font-weight: 400!important;
  padding: 4px 8px!important;
  text-decoration: none!important;
  background: #d5d5d5!important;
  border: none!important;
}
table#rw-table input {
  margin-bottom: 5px;
}
.cursor{
  cursor:pointer;
}
#loading{
  text-align: center;
  font-size: 18px;
  color:red;
  padding-bottom: 0px;
  margin-bottom: 0px;
  color:#e47027;
  font-weight: 600;
}
#rw-table td input,#rw-table th, .line-item-type{
  font-size: 12px!important;
}
.mb-3.datepick label {
    top: -9px;
    /* position: relative; */
}
.navbar-nav .basic-nav-dropdown{ 
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    height: 200px;
    background: #fff;
    border:1px solid #ced4da;
}
table td {
  font-size: 12px!important;
}
.selectedField{ 
    height: 200px;
    background: #fff!important;
}
.lineitem{
  font-size: 12px!important;
}
.m-b{
  margin-bottom: 14px;
}
.m-t{
  margin-top: 20px;
}
button.add-row {
  border: none;
  background: none;
  color: #e47027;
  font-size: 24px;
  cursor: pointer;
}

.remove-row svg{
  font-size: 30px!important;
}
.add-laytime tr{
  vertical-align: middle;
}
.excel-download{cursor: pointer!important;}

select.form-control.clear {
  font-size: 12px!important;
}
.select-btn{
  text-align: left!important;
}
.select-search{
  margin: 0px!important;
  background: #e47027;
  border: none;
  margin-left: 10px!important;
  top: -9px;
  position: relative;
  padding: 8px 10px;
  color: #fff;
  border-radius: 4px;
}
#edit-receipt{
  margin-bottom: 20px!important;
  cursor: pointer!important;
  border: 1px solid #e2dddd!important;
  background: #f8f8f8;
  padding: 4px 20px;
  margin: 0 auto;
  display: table;
  border-radius: 4px;
}
.btn-laytime{
  cursor: pointer!important;
    border: 1px solid #e2dddd!important;
    background: #f8f8f8!important;
    padding: 4px 20px!important;
    border-radius: 4px!important;
    color: #333!important;
    margin: 0 auto!important;
    display: table!important;
}
.remove-row{border: none;
  background: none;
  color: #e47027;
  font-size: 12px;
  cursor: pointer!important;
}
.d-p .react-datepicker-wrapper{
   width: 100%;
}
.add-row:hover, .remove-row{
  background: none!important;
}
.d-f{display: flex;margin-bottom: 10px;}
#add-remove button:hover, #view-filters button:hover {
  background: transparent!important;
}
.laytime-btn{    margin-bottom: 20px!important;
  cursor: pointer!important;
  border: 1px solid #e2dddd!important;
  background: #f8f8f8!important;
  padding: 4px 20px!important;
  border-radius: 4px!important;
  color: #333!important;
  margin: 0 auto!important;
  display: table!important;
   }
#view-filters select:nth-child(1){
  width: 50%;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    padding: 6px;
    margin-bottom: 4px;
    margin-right: 2px;
}
.edit-views select{
  width: 32%;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  padding: 6px;
  margin-bottom: 4px;
  margin-right: 2px;
}
div#view-filters div select:nth-child(1) , .availableFields{
  text-transform: capitalize;
}
.account-field select:nth-child(1){
  width: 20%;
}
.account-field input:nth-child(3){
  width: 10%;
}
.account-field select:nth-child(2){
  width: 10%;
}
 .edit-views input {
  width: 32%;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  padding: 6px;
  margin-bottom: 4px;
  margin-right: 2px;
} 
:focus-visible {
outline: none!important;}
.react-datepicker-wrapper {
  border: 1px solid #d3d2d2!important;
  padding: 6px 6px 6px 0px!important;
  width: 100%!important;
}

.text-danger {
  color: #dc3545!important;
  font-size: 12px;
  top: -12px;
  position: relative;
}


.col-md-6 {
  padding-left: 0px!important;
  padding-right: 2px!important;
}
.form-floating > label{
  font-size: 11px;
}
.tools .form-floating{
  width: 100%!important;
} .form-floating{
  width: 100%!important;
  margin-bottom: 6px!important;
}
.tools {
  position: relative;
}
.tools button{
  top: 10px;
  position: absolute;
  right: 0px;
}
.react-datepicker__input-container{
  border: none!important;
}
#view-filters select:nth-child(2){
  width: 20%;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    padding: 6px;
    margin-bottom: 4px;
    margin-right: 2px;
}
textarea#floatingInput{
  height: calc(3.5rem + 2px)!important;
    padding: 20px 12px 40px 12px;
    font-size: 13px;
}
div#view-contract-ship .date-picker, div#view-contract-ship .dropDown {
  margin-bottom: 20px!important;
}
#view-filters input:nth-child(3){
  width: 20%;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    padding: 6px;
    margin-bottom: 4px;
    margin-right: 2px;
}
#view-container{
  background: #fff;
    margin: 0 2% 0 2%;
    padding: 20px;
    box-shadow: 0px 0px 12px #cecaca;
}
#add-remove button, #view-filters button {
    border: none;
    text-decoration: underline;
    color: #e47027;
    background: none;
    font-size: 13px;
}
.datepick input.custom-input:focus {
    outline: none!important;
}
.react-datepicker__input-container input {
        width: 100%;
    padding: 20px!important;
    font-size: 15px;
    padding-left: 9px!important; 
 
}
.react-datepicker__input-container {
    position: relative;
    display: inline-block;
    width: 100%;
    padding-bottom: 7px!important;
}
.contracts .dropDown {
    margin-bottom: 17px!important;
}
span#renewal {
    background: #f5c9ae;
    color: #dc6a23;
    border-radius: 4px;
    padding: 2px 10px;
}
.react-datepicker__input-container input::placeholder {
    
    color: #1d67b7!important;
}
.navbar-nav {
   width: 100%!important;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255,255,255,.55);
  flex-grow: 1;
  flex-basis: 0;
}
#view-page table{
  font-size: 14px!important;
}
#orders h4.heading {
    padding-top: 12px;
}
#contracts p span {
    color: #2262a7;
    font-size: 13px;
    text-align: center;
}
#contracts p { 
    font-size: 13px; 
}
td.sorting_1 a {
    text-transform: uppercase;
    color: #e47027;
}
.account-record{
  background: #15487f;
    border: none;
    /* margin: 0 auto; */
    display: table;
    padding: 7px 45px;
    color: #fff;
    box-shadow: 0px 0px 6px #214a76;
    border-radius: 4px;
    margin-top: 10px;
}
.menu-item:hover{color:#f5ac7f;text-decoration: none!important;}
.progressbar {
  width: 100%!important;
  height: 10px;
  background-color: #cfcaca;
  margin-bottom: 28px;
  border-radius: 10px;
  }
  input.w-20.form-control {
    width: 20%;
}
  .progressbar div {
    width: 33.3%;
    height: 100%;
    background-color:#e47027;
    border-radius: 10px;
  }

.search{
  position: relative;
  box-shadow: 0 0 40px rgba(51, 51, 51, .1);
    
  }
  .dataTables_wrapper .dataTables_info {
    clear: both;
    float: left;
    padding-top: 0.755em;
    font-size: 14px;
}
.dataTables_wrapper .dataTables_paginate {
  float: right;
  padding-top: 0.25em;
  text-align: right;
  font-size: 14px;
}
.footer p{ 
    text-align: center!important; 
}
.footer {
    background: #e7e4e4;
    padding: 15px 24px 1px 24px;
    margin-top: 30px;
    text-align: center!important;
    /* clear: both; */
    width: 100%;
}
.dashboard {
  padding: 2% 5% 0 5%;
}
.dashboard h2 {
  font-size: 19px!important;
  font-weight: 500;
}
div#dashboard {
  padding: 0 5% 0 5%;
}
div#dashboard .col-md-3 .bg-info {
  background: #fff!important;
  border: 1px solid #dfdbdb!important;
  margin-bottom: 19px;
  text-align: center;
  padding: 38px;
  box-shadow: 0px 0px 18px #dddbdb;
  
}
.search input{
    height: 42px;
    text-indent: 25px;
    border: 2px solid #c9c3c3;
    margin-left: 6px;
    margin-top: 8px;
}
.user-name >div>div {
    color: #e47027!important;
}
  label {
    font-size: 13px;
    color: #497aaf;
    padding-left: 4px;
    padding-top: 12px;
    padding-bottom: 4px;
}
  .user-name{
    color: #e47027;
    text-transform: capitalize;
  }
  .user-name svg {
    background: #15487f;
    color: #fff;
    border-radius: 50px;
    padding: 3px;
    font-size: 30px;
}
  button#dropdown-basic {
    background: transparent;
    color: #fff;
    border-radius: 50%;
    padding: 10px;
    border: 0px solid #15487f!important;
    margin-top: 4px;
    font-size: 14px;
  }
  .mb-3.birthDate input {
    font-size: 14px;
}
#tables_wrapper label {
  color: #333!important;
}
#admin-label {
  line-height: 1.1;
  text-align: left!important;
  top: 8px;
  position: relative;
}
#m-0{
  margin-bottom: 0px;
}
p#admin-label span:nth-child(1) {
  color: #787676;
  font-size: 12px;
}
div#add-account h2 {
  font-size: 18px!important;
}
ol.breadcrumb li {
  font-size: 14px!important;
}
p#admin-label span:nth-child(3) div div {
  font-size: 18px;
  color: #e47027!important;
  font-weight: 600;
}
.p-1 {
  padding: 0px 10px 10px 10px!important;
}
button#dropdown-basic:hover {
  background: transparent!important;
  outline: none;
}
.p-1{
  padding-bottom: 10px;
}
  #salutation{
    display:flex;
  }
  #salutation div:nth-child(2){
    flex-grow: 1;
    margin-left: 4px;
  }
  /*#contact-title{
    margin-top: 33px;
  }*/
  .successDiv .result,
  .successDiv1 .result,
  .successDiv2 .result,
  .successDiv3 .result,
  .successDiv4 .result,
  .successDiv5 .result,
  .successDiv6 .result,
  .successDiv7 .result,
  .successDiv8 .result,
  .successDiv9 .result,
  .successDiv10 .result,
  .successDiv11 .result,
  .successDiv12 .result,
   .contact-success .result{

    background: #b6e6b2;
    width: 40%;
    margin: 0 auto;
    padding: 20px;
    text-align:center;
    margin-top: 20px;
  }
  p.actions {
    margin-bottom: 2px; 
}
p.actions a {
  color: #e47027;
}
  .button-list {
    color: #e47027;
}
.font-weight-bold.tableId { 
  color: #e47027;
}
.sc-hLBbgP.sc-eDvSVe.sc-ipEyDJ.glyQNg.bQdYbR.dlSNBX.rdt_TableCol {
  font-weight: 700;
}
#header{
  padding: 10px 32px!important;
}

  .search input:focus{

   box-shadow: none;
   border: 2px solid blue;


  }

  .search .fa-search{

   position: absolute;
   top: 20px;
   left: 16px;

  }
 
  div#search-result h3 {
    font-size: 14px !important;
    margin-top: 11px;
    font-weight: 600;
    padding: 6px 6px 6px 11px;
}

div#search-result h4{
  font-size: 16px;
  margin-top: 26px;
  font-weight: 600;
  margin-bottom: 10px;
}
div#search-result h2 {
  color: #e47027;
  font-size: 14px !important;
  font-weight: 600;
  margin-top: 30px;
  text-decoration: underline;
}
.select-quote-product-content {
  height: 350px;
  overflow: scroll;
}
div#search-result{
  display: block!important;
}
div#search-result a {
    color: #e47027;
}
#search-result .fa-search:before {
  content: "\f002";
  top: -8px;
  position: relative;
  color: #b4aeae;
  left: 4px;
}


div#search-result .search button {
  position: absolute;
  top: 2px;
  right: -5px;
  height: 38px;
  width: 67px;
  background: #e47027;
  border: none;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  color: #fff;
}
.order-error {
  margin-top: -11px;
  margin-bottom: 8px;
}
.mb-3.tooltips-right {
  display: flex;
}
.mb-3.tooltips-right #tooltip {
  right: 30px!important;
  opacity: 0.5 !important;
  position: relative;
  font-size: 14px!important;
  cursor: pointer!important;
  top: 9px!important;
}
.loading{width:20%!important;margin: 0 auto;display: table;}
  .search button{

    position: absolute;
    top: 8px;
    right: -53px;
    height: 41px;
    width: 40px;
    background: #e47027;
    border: none;
    /* border-top-right-radius: 5px; */
    /* border-bottom-right-radius: 5px; */
    color: #fff;
    border-radius: 6px;
    }
  
  .logo{
    width: 120px;
    height: 40px;
    margin-top: 9px;}
div#navbarScroll div {
  width: 100%;
  display: flex;
}
div#navbarScroll a.nav-link {
  padding: 15px;
}
p.actions span {
    color: #e47027;
    padding-right: 9px;
}
.navbar-dark {
  background-color: #15487f!important;
  border-bottom: 6px solid #082748;
}
.dtqzBx div:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
}
div#navbarScroll a.nav-link {
  padding: 20px 2px;
  font-size: 13px;
  text-align: center;
  color:#e6e3e3!important;
}
h2{font-size: 22px!important;}
div#navbarScroll {
  color: #15487f;
  padding: 0 14px;
}
/* login */
.login{
  width: 25%; 
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    box-shadow: 0px 0px 10px #a5a2a2;
    padding: 36px 20px;
    text-align: center;
    background: #fff;
}
img.login-image {
  width: 135px;
  padding-bottom: 20px;
}
.login .btn {
  width: 100%;
  margin-top: 10px;
  padding: 13px;
  background: #e47027;
  border-color: #e47027;
}
.login h4{
  font-size: 20px;
    padding-bottom: 10px;
    color: #15477f;
}

.page-header div {
  flex-basis: 0;
  flex-grow: 1;
}
.actions svg{
  color:#e47027;
}
.page-header div:nth-child(2) {
  text-align: right;
}
.sc-eDWCr.cLrkOt {
  font-weight: 600!important;
}
.tasks, .add-account {
  background: #fff;
  padding: 20px;
  box-shadow: 0px 10px 20px #d6d1d1;
  margin: 0 8px 0px 3px;
  overflow: auto;
}
table th {
    font-size: 13px;
}

.col-md-6.links p a, .col-md-6.links p span{
    font-size: 13px;
    color: #000 ;
}
input#floatingInput {
    font-size: 13px;
    margin-bottom: 6px!important;
}
form.add-accounts {
  padding: 30px;
}
form.add-accounts label{
  padding-bottom: 6px;
}
form.add-accounts select{
  margin-bottom: 6px;
}
#quotes td {
  font-size: 14px!important;
}
/* table#shipment td {
  font-size: 14px!important;
} */
.account-detail-section .add-accountrecord {
  color: #e47027;
  text-decoration: underline; padding: 7px 15px!important;
  border: none!important;
  border-radius: 4px!important;
  cursor: pointer;
}
.edit-view input,.edit-view select {
  font-size: 13px!important;
}
p.edit-btn {
  text-align: center;
}
.edit-date {
  padding-top: 0px!important;
  padding-bottom: 10px!important;
  top: 6px;
  margin-bottom: 0px!important;
  background: transparent;
}
p.link-texts span, p.link-texts span a {
  color: #e47027;
  text-decoration: underline;
  margin-right: 5px;
  font-size: 11px;
}
.edit-btn input {
  margin-right: 10px !important;
  margin-bottom: 1px !important;
  margin: 0 auto;
  font-size: 14px;
  border: 1px solid #e2dddd;
  background: #f8f8f8;
  padding: 4px 20px;
  border-radius: 4px;
  top: -1px;
  position: relative;
}
p.edit-btn button {
  margin-right: 10px!important;
    margin-bottom: 4px!important;
    margin: 0 auto;
    font-size: 14px;
    border: 1px solid #e2dddd;
    background: #f8f8f8;
    padding: 4px 20px;
    border-radius: 4px;
    color: #333;
} 
button.tooltips.btn.btn-success {
  padding: 0px;
  color: #333;
  background: transparent;
  border: none;
}
.oppEdit .dropDown{
  margin-bottom: 15px;
}
button.tooltips:hover{
  background-color: transparent!important;
}
#help{
  color: #e47027;
  font-size: 20px!important;
  width: 27px;
  top: -2px;
  position: relative;
  cursor: pointer; 
}
div#view-page td span {
  text-transform: capitalize;
}
div#view-page .tables td {
  width: 25%;
  padding: 4px 10px;
}
#td-right{
  text-align: right;
}
/* .account-detail-section table tr td:nth-child(2) {
  text-align: right;
}
.account-detail-section table tr td:nth-child(1) span,
.account-detail-section table tr td:nth-child(2) span {
  padding-right: 10px;
} */
.edit-delete{text-align: center;margin: 10px 10px 30px 10px;}
.edit-delete span:nth-child(2) {
  margin-bottom: 20px!important;
  border: 1px solid #e2dddd;
  background: #f8f8f8;
  padding: 4px 20px;
  border-radius: 4px;
  margin-left: 10px;
  margin-right: 10px;
}
.edit-delete span:nth-child(3) {
  margin-bottom: 20px!important;
  border: 1px solid #e2dddd;
  background: #f8f8f8;
  padding: 4px 20px;
  border-radius: 4px;
  margin-left: 0px;
  margin-right: 10px;
}
.edit-delete span:nth-child(4) {
  margin-bottom: 20px!important;
  border: 1px solid #e2dddd;
  background: #f8f8f8;
  padding: 4px 20px;
  border-radius: 4px;
  margin-left: 0px;
  margin-right: 10px;
}
.edit-delete span:nth-child(5) {
  margin-bottom: 20px!important;
  border: 1px solid #e2dddd;
  background: #f8f8f8;
  padding: 4px 20px;
  border-radius: 4px;
  margin-left: 0px;
  margin-right: 10px;
}
.edit-delete span:nth-child(6) {
  margin-bottom: 20px!important;
  border: 1px solid #e2dddd;
  background: #f8f8f8;
  padding: 4px 20px;
  border-radius: 4px;
  margin-left: 0px;
}
#edit{
  margin-bottom: 20px!important;
  cursor: pointer;
    border: 1px solid #e2dddd;
    background: #f8f8f8;
    padding: 4px 20px;
    border-radius: 4px;
}
.time-adding input {
  margin-right: 4px;
  padding: 0px;
  font-size: 12px;
  height: 28px;
}
button.add-row svg, .remove-row svg {
  font-size: 18px;
  top: -6px;
  position: relative;
}
.links{
  color: #e47027;
  text-decoration: underline;
  padding: 7px 15px!important;
  border: none!important;
  border-radius: 4px!important;
  cursor: pointer;
}
span#links {
  color: #e47027;
  text-decoration: underline;
  padding: 0px 15px!important;
  border: none!important;
  border-radius: 4px!important;
  cursor: pointer;
  margin-top: 0px;
  font-size: 13px;
}
.add-accountrecord {
  color: #fff;  padding: 7px 15px!important;
  border: none!important;
  border-radius: 4px!important;
  cursor: pointer;
}
form.add-accounts input.btn.btn-primary {
  /* margin-top: 10px!important; */
  background: #e47027;
  border-color: #e47027;
}
.form.add-account h1 {
  font-size: 24px;
  color: #15487f;
  padding-bottom: 10px;
}
.add-account .footer {
  background: transparent;
}
button:disabled {
  display: none;
}
.form-floating label {
  color: #1d67b7;
}
.form-floating textarea {
  margin-bottom: 12px;
}
input.reference, input.reference_date {
  margin-left: 10px;
}
.add-account .footer button:nth-child(2) {
  float: right;
  background: #e47027;
  border: none;
  padding: 6px 20px;
  color: #fff;
  border-radius: 4px;
}
.successdiv{
  text-align: center;
    color: green;
     
    clear: both;
}
.cLrkOt {
  overflow: initial!important;
  white-space: nowrap!important;
  text-overflow: initial!important;
  font-size: 15px!important;
}
.kcxRMj {
  font-size: 14px!important;
}
button:hover, .btn-primary:hover {
  background: #ce5f18;
  border-color: #ce5f18;
}

a.nav-link svg {
  color: #debfab;
  top: -1px;
  position: relative;
  padding-right: 2px;
}
.add-account .footer button:nth-child(1) {
  float: left;
  background: #e47027;
  border: none;
  padding: 6px 20px;
  color: #fff;
  border-radius: 4px;
}
.page-header {
  display: flex;
  padding: 16px 0!important;
    margin: 0 2% 0 1%;
}
.loading-text{
  text-align: center;
    color: #e47027;
    font-size: 14px;
}
.form-floating span {
  color: red;
  font-size: 12px;
}
.css-13cymwt-control {
  padding: 9px!important;
  color: red;
}
 
.breadcrumb { 
  margin-bottom: 0rem!important;
}
.page-header button {
  margin-top: 10px!important;
  background: #e47027;
  border-color: #e47027;
}
.page-header div:nth-child(2) button a {
  color: #fff;
  text-decoration: none;
}
div#add-account {
  padding-top: 3%;
}
.footer span {
  color: #15487f;
}
/* floating*/
.form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label 
{
  opacity: 0.9!important;
  transform: scale(.85) translateY(-0.5rem) translateX(0.15rem);
  color: #15487f!important;
}

.sc-dkrFOg.iSAVrt.rdt_TableHeadRow {
  font-size: 14px;
}
.rdt_TableCell {
  font-size: 16px;
}
.hbotQl {
  overflow: hidden;
  /* white-space: nowrap; */
  /* text-overflow: ellipsis; */
}
/*h4.heading {
  font-size: 16px;
    color: #e47027;
    padding-bottom: 0px;
}*/
div#view-container p,
div#view-container select,
div#view-container label,
div#view-container .selectedFields div,
div#view-container input {
    font-size: 13px;

}
#trash{
  cursor: pointer;
}
.available p{
  text-align: center;
    margin-top: 10px;
    color: #497aaf;
}
p#filter-by {
    margin-bottom: 11px;
    margin-top: 12px;
}
h4.heading {
  font-size: 14px;
    color: #333;
    padding-bottom: 0px;
    background: #e9eaea;
    padding: 8px;
    font-weight: 600;
    margin-bottom: 11px;
}

.form-floating>.form-control, .form-floating>.form-select {
  height: calc(2.5rem + 2px)!important;
  padding: 1rem 0.75rem;
}

.overlay {
  position: absolute;
  top: 100%; /* Adjust this value according to your layout */
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  z-index: 1000; /* Adjust the z-index value to ensure it's above other elements */
  display: none; /* Initially hide the overlay */
}

.overlay.active {
  display: block; /* Show the overlay when active */
}

.overlay > div {
  background-color: #fff; /* Background color for the overlay content */
  padding: 20px;
  border-radius: 5px;
  margin-top: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Box shadow for the overlay content */
}

.overlay h3 {
  margin-top: 0;
}

.overlay ul {
  list-style: none;
  padding: 0;
}

.overlay ul li {
  margin-bottom: 10px;
}

.error-validations{
color: red;
font-size: 12px;
padding: 0px;}
.form-floating>.form-select {
  padding-top: 16px!important; 
  padding-bottom: 0.625rem;
  font-size: 12px!important;
}
/* #price .mb-3.form-floating {
    margin-bottom: 0px!important;
} */
#locked{
  padding-top: 28px;


}
h4.heading span { 
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
}
h4.heading span a{
  color: #e47027;
    text-decoration: underline;
}
.account-detail-section  .dataTables_wrapper{
  margin-bottom: 20px;
}
#inner-heading {
  text-align: center;
  font-size: 13px;
  margin-bottom: 0px;
  margin-top: 14px;
}
#inner-heading span  a{
  color: #fff;
  text-decoration: none;
}
#inner-heading span {
    background: #e9eaea;
    color: #fff;
    padding: 8px 13px;
    background: #e47027;
    border-color: #e47027;
    border-radius: 3px;
}
div#view-page #contact_wrapper {
  margin-bottom: 20px;
}
label {
  text-transform: capitalize;
}
a.logout-btn.dropdown-item button {
  border: none;
  padding-left: 0px;
}
p#owner {
  margin-bottom: 0px;
}
td svg {
  padding-left: 6px;
  font-size: 20px;
}
p#owner span {
  background: #f8d4bd;
  padding: 2px 10px;
  font-size: 14px;
  border: 1px solid #f3d4c0;
  border-radius: 4px;
  color: #e47027;
}
.priceDiv .result, .result p{
  background: #b6e6b2;
    width: 40%;
    /* margin: 0 auto; */
    padding: 8px;
    text-align: center;
    margin-top: 20px!important;
    margin: 0 auto;
}
select#product-code {
    margin-bottom: 16px;
}
p.result {
  color: green;
  text-align: center;
  padding-top: 20px;
}
tr.odd {
    background: #f9f9f9!important;
    
}
#details {
  font-size: 23px;
}
p#account-name span:nth-child(1) {
    color: #2262a7;
    font-size: 13px;
}
p#account-name span:nth-child(3) {
  font-weight: 600;
  font-size: 22px;
}
p#account-name {
  text-align: center;
  line-height: 1.3;
}
p#account span:nth-child(1) {
  color: #2262a7;
  font-size: 13px;
}
p#account span:nth-child(3) {
font-size: 15px;
}
.opp-details td span {
  color: #2262a7;
  font-size: 13px;
  text-align: center;
}
.opp-details td { 
  text-align: center;
}
.text-right{
  text-align: right;
}
.clear{
  clear:both
}
p#account { 
line-height: 1.3;
margin-bottom: 0px;
}
table.table.table-bordered.opp-details {
  margin-top: 10px!important; 
}
table.table.table-bordered.opp-details {
  margin-top: 10px!important;
  /* clear: both; */
}
.col-md-6.accountDetails p {
  text-align: right;
  font-size: 14px;
  line-height: 1.9;
}
#orders input, #orders .dropDown {
  margin-bottom: 10px;
}
.account-table {
  text-align: center;
}
table.table.table-bordered.account-table td {
  font-size: 14px;
}
.d-flex.opd button {
    margin-right: 10px;
}
.account-table td span {
  /* color: #2262a7; changed font
  font-size: 13px;
  text-align: center; */
  color: #333;
  /* font-size: 12px; */
  text-align: center;
  font-weight: 600;
  font-size: 91%;
}
.rsw-toolbar {
  align-items: center;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
  display: flex;
  font-size: 12px;
}
p.link-texts span {
  font-size: 12px;
  font-weight: 600;
  cursor: pointer!important;
}
p.link-texts {
  text-align: center;
}
.rsw-editor {
  border: 1px solid #ddd;
  border-radius: .375rem;
  display: flex;
  flex-direction: column;
  min-height: 100px;
  overflow: hidden;
  border-top: 3px solid #e47027;
}
.btn-margin button {
  margin-right: 10px;
}
#adatas{
  font-size: 14px;
  line-height: 1.9;
}
.col-md-6.accountDetails p svg {
  color: #e47027;
  padding-right: 4px;
  font-size: 16px;
}
/* #root>div:nth-child(1){
  position:relative!important;
} */
.shipment #status, .shipment #ptype {
  margin-bottom: 16px;
}
.quotes label{
  padding-top: 6px;
}
button.btn.btn-primary.add-quote {
    background: #e47027;
    border-color: #e47027;
    margin: 0 auto;
    display: table;
    padding: 6px 40px;
    margin-top:20px;
    width:10%;
}
input.shipment-submit {
  background: #15487f;
  border: none;
  margin: 0 auto;
  display: table;
  padding: 10px;
  color: #fff;
  box-shadow: 0px 0px 6px #214a76;
  border-radius: 4px;
  width: 12%;
  text-align: center;
}
tr.even td {
  background: #fff;
}
tr.odd td, tr.even td {
  border-bottom: 1px solid #e3e2e2;
  font-size: 13px;
}
th {
  font-size: 14px;
}
.price-submit { 
  border: none; 
  padding: 7px 45px;
  color: #fff;
  /* box-shadow: 0px 0px 6px #214a76; */
  border-radius: 4px;
  margin: 0 auto;
    display: table;
    color: #000;
    /* box-shadow: 0px 0px 6px #214a76; */
    border-radius: 4px;
    margin-bottom: 20px!important;
    border: 1px solid #e2dddd;
    background: #f8f8f8;
    padding: 4px 20px;
    border-radius: 4px;
}
input.addaccount-save, .contact-save, .diared,.product-sizing {
  
    margin: 0 auto;
    display: table; 
    color: #000;
    /* box-shadow: 0px 0px 6px #214a76; */
    border-radius: 4px;
    margin-bottom: 20px!important;
    border: 1px solid #e2dddd;
    background: #f8f8f8;
    padding: 4px 20px;
    border-radius: 4px;
}
.edit-cancel{
  text-align: center;
}
.account-save{ color: #000;
  /* box-shadow: 0px 0px 6px #214a76; */
  border-radius: 4px;
  margin-bottom: 20px!important;
  border: 1px solid #e2dddd;
  background: #f8f8f8;
  padding: 4px 20px;
  border-radius: 4px;
margin-right: 10px;}
#text-area{
  color: red;
    font-size: 12px;
    margin-bottom: 0;
    padding-bottom: 0px;
}
div#opportunity-section .heading {
  padding: 0sas;
  margin: 10px 0;
}
span.sr {
  font-weight: 100 !important;
}
textarea#floatingTextarea2 {
  font-size: 13px;
  height: calc(3.5rem + 2px) !important;
  margin-left: 0;
  margin-right: 0;
  padding: 20px 12px 40px;
  width: 100% !important;
  margin: 0 auto;
}
.product-table h3{text-align: left!important;    
  font-size: 14px;font-weight: 600;}
.dropDown label{
  
  font-size: 17px!important;

}
.form-floating>label {
  padding-top: 11px!important;
  font-size: 13px;
}
.mb-3 {
  margin-bottom: 5px!important;
}


/* menu */
nav {
  background: #15487f;
  /* overflow: hidden; */
  color: #fff;
  width: 100%;
}
.navbar-expand-lg .container{
  max-width: 100%!important;
}
.navbar-expand-lg {
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding: 14px;
  border-bottom: 4px solid #133f6e;
  padding: 0px;
}
.tcfs{
  left: -20px;
    position: relative;
}
.navmenu .dropdown-menu {
  top: 100%;
  left: -200px!important;
  margin-top: .125rem;
  right: 0;
  min-width: 14rem!important;
  overflow: scroll;
  height: 500px;
  text-align: left!important;
}
.navmenu .dropdown-menu a{
  text-align: left!important;
}
a#basic-nav-dropdown{
  top: 7px;
  border-radius: 4px;
  position: relative;
  left: -12px;
  padding: 2px 23px;
  border: 1px;
  border: 2px solid #fff;
  padding: 4px 35px!important;
}
.tooltips svg{
  left: 21px;
  position: relative;
  font-size: 25px!important;
  opacity: 0.5!important;
  
  cursor: pointer;

}
.tooltips svg:hover{opacity: 0.9;}
#button-tooltip,#button-tooltip1,
#button-tooltip2,
#button-tooltip3,
#button-tooltip4,
#button-tooltip5,
#button-tooltip6,
#button-tooltip7,
#button-tooltip8,
#button-tooltip9,
#button-tooltip10,
#button-tooltip11,
#button-tooltip12,
#button-tooltip13,
#button-tooltip14,
#button-tooltip15
{background-color: #fff3cd;color: #333!important;text-align: left;width: 20%;font-size: 12px;}
span.tooltips-label {
  left: 28px;
  position: relative;
  /* opacity: 0.5!important; */

} 
p.errors {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  text-align: center; 
  width: 50%;
  margin: 0 auto;
  margin-bottom: 30px;
}
.error-message {
  /* background: #f1b7b7; */
  font-size: 14px;
  width: 50%;
  padding: 10px;
  margin: 0 auto;
  margin-bottom: 10px;
  text-align: center;
  color: #a40a0a;
}
.lineitem-group {
  display: flex;
  width: 120%;
}
.lineGroup {
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content; /* Adjusts to the content width */
}

.lineGroup input {
  width: 200px; /* Adjust as needed */
  padding-right: 30px; /* Space for the icon */
  flex: 1;
}

.lineGroup button {
  position: absolute;
  right: 5px; /* Adjust as needed */
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  top:7px;
}

 .lineGroup input{font-size: 12px !important;
  padding: 5px!important;
  top: 2px;
  position: relative;
  border: 1px solid gray;
  border-radius: 4px;}
.lineitem-group  input{border: 1px solid #e0dddd;
  border-radius: 4px;}
.lineitem-group button{
  top: -2px!important;
    left: -46px!important;
    color: #333 !important;
    background: transparent;
}
.fulid-section .tasks .dropDown.form-floating, .fulid-section .select-dropdown.form-floating,  .fulid-section textarea, .fulid-section .mb-3.form-floating {
  width: 96%;
  width: 96% !important;
  margin: 0 auto;
  /* margin-bottom: 15px !important; */
  /* padding-left: 0px; */
  /* margin-left: 0px; */
  display: table;
}
#opportunity-section  .mb-3.form-floating, #opportunity-section .dropDown.form-floating, #opportunity-section textarea,
#opportunity-section .select-dropdown.form-floating {
  width: 96% !important;
  margin: 0 auto;
  margin-bottom: 15px !important;
  padding-left: 0px;
  margin-left: 0px;
}
.column-gap .mb-3.form-floating, .right-section .mb-3.form-floating, .right-section .select-dropdown.form-floating,  .right-section textarea,  .right-section .css-b62m3t-container,
.right-section .mb-3.form-floating, .right-section .dropDown.form-floating,  .right-section textarea,  .right-section .css-b62m3t-container {
  width: 96% !important;
  margin: 0 auto;
  margin-bottom: 15px !important;
  padding-left: 0px;
  /* margin-left: 0px; */
  display: table;
}
.right-section  h4.heading {
  font-size: 14px;
  color: #333;
  padding-bottom: 0px;
  background: #e9eaea;
  padding: 8px;
  font-weight: 600;
  margin-bottom: 11px;
  width: 98%;
  margin: 0 auto;
  margin-bottom: 18px;
}
.indexes-align.col-md-6 label {
  padding-left: 24px;
}
#table-styles.hidden {
  display: none;
}

.hover-show:hover + #table-styles {
  display: block;
}

/* .contracts .col-md-6:nth-child(odd), .tasks .col-md-6:nth-child(odd) {
  width: 48%;
  margin-left: 25px;
} */

/* Apply styles to even .col-md-6 elements */
/* .contracts   .col-md-6:nth-child(even), .tasks .col-md-6:nth-child(even) {
  width: 50%;
} */
table#rw-table {
  margin-top: 25px;
  margin-bottom: 30px;
  width: 100%!important;
}
.datepicker-receipt label{
  top: -6px!important;
  left: 0;
}
#history-info th,#history-info td{
padding: 4px!important;
}
.cancel-bottomm{top:44%;position: relative;}
.edit-btn a{color: #fff!important;}
.success-message{
  font-size: 14px;
  width: 50%;
  padding: 10px;
  margin: 0 auto;
  margin-bottom: 10px;
  color: #155724;
  /* background-color: #d4edda; */
  /* border-color: #c3e6cb; */
  text-align: center;
}
span#tooltip {
  right: -17px!important;
  top: -2px;
  cursor: pointer;
  position: relative;
}
span.tooltips-label {
  left: 19px;
  position: relative; 
}
/* #button-tooltip{
  
} */
/* span#tooltip {
  left: 16px;
  opacity: 0.5!important;
  position: relative;
  font-size: 14px;
  cursor: pointer;
} */
.filter-by{width: 30%!important;}
.filter-by-operator{width: 20%!important}
.filter-by-value{width: 30%!important;}
span#tooltip svg{font-size: 22px;opacity: 0.9!important;}
span#tooltip:hover{opacity: 0.9!important;}



.kyc-table {
  margin: 0 auto;
  margin-top: 4px;
}
#btn-header{
  text-align: center;
}
p#top-header {
  text-align: center;
  font-size: 14px;
}
.post-buyer-content {
  padding: 30px!important;
}
.post-buyer-content .form-check {
  margin-bottom: 8px;
  color: #333;
}
.active-menu {
  background: #e47027;
}
#contactLists td select,#contactLists td input{
  font-size: 14px;
  color: gray;
}
p#debit-note {
  text-align: center;
}
p#debit-note button:hover{
  background-color: transparent!important;
}
span#downloads {
  cursor: pointer;
  color: #e47027;
  text-decoration: underline;
}
#edits{
  margin-bottom: 0px!important;
}
p#debit-note button{
  border: none;
  background: transparent;
  text-transform: CAPITALIZE;
  font-size: 14px;
  color: #e47027;
  text-decoration: underline;
}
#contactLists  table{
  width:100%!important;
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0,0,0,.55);
  text-align: center;
  padding: 10px!important;
}
.navbar-light{
  padding-top: 0!important;
    padding-bottom: 0!important;
    border: none!important;
}
.post-buyer-content .form-check label{
  color: #333;
}
.post-buyer-content .btn{
  background: #e47027;
  border: none;
  margin-top: 10px;
}
#btn-header button{
margin-bottom: 20px!important;
    margin: 0 auto!important;
    /* display: table; */
    border: 1px solid #e2dddd!important;
    background: #f8f8f8!important;
    padding: 4px 20px!important;
    border-radius: 4px!important;
    margin-right: 10px!important;
  }
.navmenu .dropdown-menu a {
  text-align: left!important;
  background: transparent;
  color: #333!important;
}
.navmenu .dropdown-menu a a{
    color: #333!important;
}
div#view-page .tasks p span {
  text-transform: capitalize;
}
/* .dropdown-menu {
  top: 100%;
  left: -130%!important;
  margin-top: .125rem;
  right: 0;
  min-width: 14rem!important;
} */
.kyc-table{
  width: 40%!important;
}
.kyc-table td, .kyc-table th{
  text-align: left!important;
}
.kyc-table th{
  color:rgb(128, 123, 123);
}
a.dropdown-item a {
  color: #333;
  padding: 0px;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0 10px;
}
.dropdown-menu .show a{
  color: #333!important;
}
.navbar-expand-lg .navbar-nav .nav-link {
  
    flex: 0;
    /* flex-shrink: 0; */
    padding: 0px;
    flex-basis: 50%;
  /* padding-right: .5rem;
  padding-left: .5rem;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0px;
  flex-basis: auto; */
}
.nav-link{ 
  color: #fff!important;
}
nav ul { margin: 0 0 2em; }

nav ul li { float: left; }

nav ul li.more {
  width: 3em;
  text-align: center;
  display: none;
}

nav ul li.more:hover ul#overflow {
  opacity: 1;
  visibility: visible;
}
.reset-link{
  margin-bottom: 0px;
    padding-top: 14px;
    font-size: 14px;
    color: #30ab2e;

}
input.price-submit {
  margin: 0 auto;
  display: table;
}
span.status {
    background: #9ce7a9;
    padding: 2px 10px;
    border-radius: 4px;
    color: #065c0c;
}
.css-13cymwt-control {
  padding: 2px!important;
  font-size: 13px; 
}
div#react-select-3-placeholder {
  color: #3371b4;
}
.css-1jqq78o-placeholder {
  grid-area: 1/1/2/3; 
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
  color: #2e639c!important;
}
.css-13cymwt-control {
  margin-bottom: 10px!important;
}


.css-1xc3v61-indicatorContainer svg {
  font-size: 17px!important;
  rotate: 179deg!important;
  cursor: pointer!important;
  color: #333!important;
}

div#contract-ship-drop form select, div#contract-ship-drop form input {
  color: #000!important;
}
span.clears svg {
  font-size: 17px!important;
  rotate: 44deg!important;
  cursor: pointer!important;
  color: #333!important;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
#shipment .col-md-6{
  margin-top:16px;
}
.m-b .col-md-6 {
  margin-bottom: 10px!important;
}
p#link a {
  color: #e47027;
  top: 8px;
  position: relative;
}
.m-contract .dropDown, #product-master .dropDown {
  margin-bottom: 10px!important;
}
/*#view-page h4.heading {
    padding-top: 20px;
}*/
.dn{
  display:none!important;
}
/*#view-page h4.heading {
  padding-top: 20px;
}*/
hr{
  display: none!important;
}
.tables td, .tables tr {
  border: none!important;
  text-align: left;
}
#view-page div#opp_filter {
  margin-bottom: 16px;
}
#codes{
  color:#e47027;
    text-decoration: underline;
}
div#contracts {
  max-width: 100%!important;
}
/* #view-page td, #view-page tr, #view-page table   {
  border:none;
  text-align: left;
} */
hr:not([size]) {
    height: 1px;
    margin: 0 0 20px 0;
    border-top: asa;
    height: 3px!important;
    width: 5%!important;
    color: #e47027;
    opacity: 2;
}
#contacts #contact-title {
  margin-top: 16px!important;
}
table tr th {
  text-transform: capitalize;
  font-size: 12px;
}
.dataTables_wrapper {
  position: relative;
  clear: both;
  padding: 18px 7px;
}
#contacts #account_name {
  margin-top: 0px!important;
  margin-bottom: 16px;
}
span.account-record {
  background: #fbd8c2;
  padding: 2px 8px;
  border-radius: 4px;
  color: #e47027;
  box-shadow:none!important;
}
 p#forgot {
    margin-bottom: 0px;
    padding-top: 16px;
    font-size: 14px;
}
nav ul li a,
nav ul li span {
  display: block;
  background: #DA4453;
  color: #fff;
  text-decoration: none;
  padding: 1em;
  cursor: pointer;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
}

nav ul li a:hover,
nav ul li span:hover { background: #ED5565; }

nav #overflow {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  text-align: left;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
}

nav #overflow li { float: none; }
td svg {
  color: #e47027;
}
nav #overflow li a {
  background: #34BC9D;
  white-space: nowrap;
}
.addAccount form,
.priceAccount form,
.contactAccount form {
  background: #fff;
  margin: 0 10px 0 4px;
  padding: 20px;
  box-shadow: 0px 0px 12px #cecaca;
}
p.space-hight {
  height: 8px;
}
.edit-delete span {
  font-size: 14px;
}



p.date-error {
  text-align: center;
  padding: 0px 10px 10px 10px;
}
p.date-error span {
  color: red;
  /* color: #721c24; */
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 14px;
  border-radius: 8px;
  font-size: 14px;
}
.row.dropdowns-form select, .row.dropdowns-form p, .row.dropdowns-form label, .row.dropdowns-form input {
    font-size: 13px!important;
}
.dropdown-form select,
.dropdown-form p,
.dropdown-form label,
.dropdown-form input {
    font-size: 13px!important;
}
nav #overflow li a:hover { background: #46CFB0; }
.o-hidden {
  overflow:hidden;
}

.nav {
  transition: transform 0.4s;
  transform: translateX(50%);
  left: -50%;
}

.nav.justify-content-end {
  transform: translateX(0);
  left: 0;
}
.navbar-dark .align-items-center{
    width: 100%!important;
      overflow: hidden!important;
}
.navbar.navbar-expand-lg.navbar-dark li a {
  color: #fff;
  padding: 15px 0px;
}
.navbar.navbar-expand-lg.navbar-dark svg {
  color: #fff; 
}
li.nav-item a {
  text-transform: capitalize;
}
.bithDate{
  font-size: 14px;
}
/* menu */
nav#menu-container {
  background:transparent;
  position:relative;
  width:100%;
  height: 56px;
}
#btn-nav-previous {
  text-align: center;
    color: #333;
    cursor: pointer;
    font-size: 16px;
    position: absolute;
    left: 5px;
    padding: 1px;
    background: #fff;
    border-radius: 27px;
    top: 14px;
}
#btn-nav-next {
  text-align: center;
    color: white;
    cursor: pointer;
    font-size: 16px;
    position: absolute;
    right: 5px;
    top: 14px;
    background: #b4babb;
    fill: #333;
    padding: 1px;
    background: #fff;
    border-radius: 27px;
}
.menu-inner-box
{ 
  width: 100%;
  white-space: nowrap;
  margin: 0 auto;
  overflow: hidden;
  padding: 0px 34px;
  box-sizing: border-box;
}
.menu
{  
  padding:0;
  margin: 0;
  list-style-type: none;
  display:block;
  text-align: center;
}
.nav-item.show.dropdown .menu-item{
  line-height: normal!important;
}
/* body {
  overflow-x: hidden;
} */
.navbar-light .navbar-nav .nav-link {
  color: rgba(0,0,0,.55);
  text-align: center;
}
.menu-item
{
  height:100%;
  /* padding: 0px 15px; */
  color:#fff;
  display:inline;
  font-size: 13px;
  margin:0 auto;
  line-height:26px;
  text-decoration:none;
  text-align:center;
  white-space:no-wrap;
}
.menu-item:hover {
  text-decoration:underline;
}



.sc-gswNZR.hIWEKa.rdt_TableHead .hbotQl {
  text-transform: capitalize;
}



@media only screen and (max-width: 480px) {

  .menu-inner-box
  { 
      width:100%;
      overflow-x:auto;
  }
}
/*calledar icon*/
.react-datepicker__input-container input {
    width: 100%;
    padding: 8px 8px 0px 8px!important;
    border: none!important;
    font-size: 13px;
    padding-left: 11px!important;
    top: 8px;
    position: relative;
}
.mb-3.date-picker label {
    top: -10px;
    position: reasas;
}
.react-datepicker__input-container {
    position: relative;
    display: inline-block;
    width: 100%;
    background: #fff;
}
.example-custom-input:focus-visible {
  border-bottom-color: #b51148;
  outline: none;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #b51148;
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px white;
  border: 1px solid #b51148;
  margin: 0.066rem;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #b51148;
  border-radius: 50%;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 50%;
}

.example-datepicker-class .react-datepicker__today-button {
  color: #b51148;
  background-color: white;
  border-top: 0px;
  text-align: right;
  margin-right: 20px;
}
.react-datepicker__input-container {
    position: relative;
    display: inline-block;
    width: 100%;
    /* border: 1px solid #ced4da!important; */
    padding: 0px 2px 2px 2px!important;
}
div#bank input {
    margin-bottom: 14px;
}

/*New Form account*/
form.d-flex.dropdown-form {
    box-shadow: none!important;
}
form.d-flex.dropdown-form .btn {
  margin: 0px!important;
    background: #e47027;
    border: none;
    margin-left: 10px!important;
}
.col-md-6.links p {
    padding: 18px 20px 0px 0px;
}
.accountName {
  font-weight: 600!important;
  color: #e47027!important;
}
#table-styles{ 
    border-top: 4px solid #e47027!important;
    border-radius: 4px;
    border: 1px solid #d8d5d5;
    background-color: #f8f8f8;
    margin: 14px 1px 14px 1px;
}
#table-styles .dataTables_wrapper{
  background-color: #fff!important;
}
input::-moz-placeholder {
  font-size: 13px;
} /* Firefox 19+ */
input:-moz-placeholder { font-size: 13px;}  /* Firefox 18- */
input:-ms-input-placeholder { font-size: 13px;} /* IE */
input.opportunity-record-type, .record-type,.owner-role {
  color: #e47027!important;
  font-size: 13px!important;
  font-weight: 600!important;
}
.post-shipment .dropDown{
  margin-bottom: 10px;
}
h1.no-data-detail {
  text-align: center;
  font-size: 20px;
  padding: 4%;
  color: #e47027;
}
.create-view-table .sc-hLBbgP a {
  font-size: 12px;
  color: #e47027;
}
.create-view-table .sc-hLBbgP div{
  font-size: 12px;
}
.create-view-table .sc-hLBbgP{
  font-size: 14px;
}

.random-.d-flex select {
  font-size: 13px;
}
.sc-hLBbgP input {
  display: none;
}

.navbar a.active {
  background: #e47027!important;
}


p.bill-group span, p.ship-group span {
  color: #333;
  font-size: 12px;
  line-height: 1.9;
}
input.table-search {
  font-size: 13px;
  border: 1px solid #b5b2b2;
  margin-top: 20px;
}


#view-page .add-accountrecord {
  color: #e47027;
  text-decoration: underline;
}
.preshipment .dropDown.form-floating {
  margin-bottom: 20px;
}
#table-styles .heading span:nth-child(1){float: left;}
#table-styles .heading a{
  color: #333!important;text-decoration: none!important;}
  #table-styles .heading span:nth-child(2){border: 1px solid #e2dddd!important;
    color: #333!important;
    padding: 6px 19px!important;
    text-decoration: none;
    border-radius: 4px;
    margin-left: -14%;}
#table-styles .heading{
  background: #f8f8f8 !important;
  text-align: center;
  width: 100%;
  padding: 20px 0 10px;
  margin: 0;
  border-bottom: 1px solid #DDD;
  overflow: hidden;
 }
 p.edit-btn.btn-disabled button {
    display: inline; 
}
 .spaces.col-md-6 {
  margin-bottom: 30px;
}
.dropDown.form-floating {
  margin-bottom: 15px !important;
}

.mb-3.post-date.form-floating label {
  top: -7px;
}
 .form-floating>.form-select {
  padding-top: 1.625rem;
  padding-bottom: 0px!important;
  padding-bottom: 1px;
  top: 0px;
  position: relative;
}

div#react-select-3-placeholder {
  color: #3371b4;
  top: 6px;
  position: relative;
}
 .text-danger {
  color: #dc3545 !important;
  font-size: 12px;
  top: 4px;
  position: relative;
}
#view .add-accountrecord {
  color: #e47027!important;
}
#view-page .dataTables_wrapper {
  margin-bottom: 10px;
  padding: 10px;
}
#view div .dataTables_wrapper {
  margin: 20px 0;
}
.link-text{font-weight: 600!important;
  color: #e57027!important;}
.new-btn{
  color: #e47027;
  text-decoration: underline;
  padding: 7px 15px!important;
  border: none!important;
  border-radius: 4px!important;
  cursor: pointer;
}
form.d-flex.dropdown-form label {
    top: 6px;
    position: relative;
    margin-right: 10px;
    font-size: 16px;
}
#tcf table,
#quotes_wrapper table,
#shipment_wrapper table {
    font-size: 14px!important;
    border-top: 1px solid #d9d8db;
}
div#tcf,
div#quotes_length,
div#shipment_length {
    margin-bottom: 13px;
}
table#quotes td {
    font-size: 13px!important;
}
table#tcf {
    font-size: 13px!important;
}
p#new-offer {
    margin-bottom: 11px;
    text-align: center;
}

 #tcf-banner select{
  margin-bottom: 12px;
}
#tcf-banner input{
  margin-bottom: 12px;
  margin: 0 auto;
    display: table;
    color: #000;
    /* box-shadow: 0px 0px 6px #214a76; */
    border-radius: 4px;
    margin-bottom: 20px!important;
    border: 1px solid #e2dddd;
    background: #f8f8f8;
    padding: 4px 20px;
    border-radius: 4px;

}
#tcf_wrapper, 
#shipment_wrapper, 
div#quotes_wrapper {
    margin-bottom: 20px;
}
#quotes table.dataTable thead th,
#quotes table.dataTable thead td,
#quotes table tr{
    font-size: 14px!important;
    border-bottom: 1px solid #d9d8db;
}
p#new-offer span {
    border: none;
    text-decoration: underline;
    color: #e47027;
    background: none;
    font-size: 13px;
    cursor:pointer;
}
.links p a{
  color:#333;
}
.accounts-page .row:nth-child(2) {
    border-top: 4px solid #4273a8;
    border-radius: 4px;
    margin: 1px;
    background: #f8f8f8;
    padding: 10px;
}
.accounts-page{
  background: #fff;
    padding: 0px;
    box-shadow: 0px 10px 20px #d6d1d1;
    margin: 0 2% 0 1%;
    overflow: hidden;
}
.accounts-page .row:nth-child(2) h6,
.accounts-page .row:nth-child(3) h6 {
  margin-bottom: 10px;
    overflow: auto;
    padding-top: 10px;
    font-weight: 600;
}
/* .accounts-page .row:nth-child(2) thead th{
  font-weight: 500;
} */
.accounts-page .row:nth-child(2) thead,
.accounts-page .row:nth-child(3) thead {
    font-weight: 500;
}
.accounts-page .row:nth-child(2) thead,
.accounts-page .row:nth-child(3) thead {
  background: #eeeeee;
  font-size: 13px;
}
.accounts-page .row:nth-child(2) td,
.accounts-page .row:nth-child(3) td {
    font-size: 12px;
}
.accounts-page .bg-info {
    background: transparent!important;
}
.accounts-page .row:nth-child(3) .bg-info {
  border-top: 4px solid #4273a8!important;
  border-radius: 6px;
}
.accounts-page .row:nth-child(3) {
    
    border-radius: 4px;
    margin: 1px;
    background: #f8f8f8;
    padding: 10px;
}
.account-reports {
    background: #fff;
    font-size: 13px;
}
.account-reports p {
    padding-bottom: 0px;
    margin-bottom: 5px;
    text-decoration: underline;
}
.account-reports {
    border: 1px solid #e5e2e2;
    padding: 10px;
}
.dropdowns-form form{
  margin: 0px;
  padding: 0px;
  box-shadow: none!important;
}
.dropdowns-form .col-md-6.links p {
    padding: 8px 20px 0px 0px;
}
.row.dropdowns-form {
    border-bottom: 4px solid #4273a8;
    margin-bottom: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
}
.account-view{
  margin: 0 2% 0 2%;
}
.create-table td, .create-table th {
  padding: 8px;
}
.link-popup{
  color: #e57126;
  text-decoration: underline;
  cursor: pointer;
}
.top-dropdown{
   
    word-wrap: normal;
    width: 100%;
    padding: 6px;
    border: none;
 
}
.hba-section button {
  background-color: #fff;
    padding-bottom: 6px;
    padding-top: 24px;
  
}
 
.hba tr th {
  padding: 22px 4px;
    background: #da9694;
    border-right: 1px solid #e5e0e0;
    text-align: center;
    vertical-align: middle;
}
.hba-section{background-color: #fff;padding-bottom: 6px;padding-top: 20px;}
.hba tr td input {width: 102%!important;font-size:11px;    padding-top: 4px;}
.hba tr td input:disabled {
  pointer-events:none;
  color:#000;
  background:rgb(241, 240, 240);
}

.hba tbody td {
  border: none!important;
  padding: 0px!important;
}
span a{color: #000;text-decoration: none;}
.search-bg span{
  border: 1px solid #e9eaea;
  padding: 4px 14px;
  background: #e9eaea;
  color: #333;

}
.time-calulation span:nth-child(0) {
  padding-right: 37px;
}
td.laytime-action svg {
  font-size: 20px;
  cursor: pointer;
}
.time-calulation p {
  font-size: 12px;
  margin: 1px;
}
.time-calulation p  span {
  padding-right: 20px;
}
.search-bg .form-floating{
  width: 89%!important;
}
.search-bg{
  margin-bottom: 15px;
}



/* accordian */
/* .accordion-button::after {
   
  padding-right: 15px!important;
  right: 11px!important;
  position: relative!important;
  margin-left: initial!important;
} */

.accordion__button {
  background-color: #f4f4f4;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  background: transparent;
  padding: 8px;
  border-top: 1px solid #3778be;
  font-weight: 600;
  font-size: 14px;
}
.accordion__button:before {
  display: inline-block;
  content: '';
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
  top: -3px;
  position: relative;
  font-weight: 600;
}
p.no-records {
  padding: 4px 4px 4px 17px;
}
#move-up-down{
  float: left;
  width: 10%;
}

div#move-up-down p svg {
  margin-bottom: 10px;
  cursor: pointer;
}

select.form-control.selectedField {
  text-transform: capitalize;
}
select.form-control.selectedField, .selectedFields {
  text-transform: capitalize;
  font-size: 13px!important;
}
div#move-up-down p {
  cursor: pointer;
  display: grid;
  font-size: 22px;
  margin-bottom: 14px;
  cursor: pointer;
  padding: 160% 0;
  margin: 0px;
  color: #333;
}
div#createview-add-remove {
  float: left;
  width: 10%;
}
#right-select-section{background: #fff!important;
  display: block;
  width: 80%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  float: left;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  /* border: 1px solid #ced4da; */
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  margin-left: 0px;
}
#right-select-section .selectedFields{
  height: 200px;
    border: 1px solid #ced4da;
}
#table-styles .heading span:nth-child(2) {
  border: 1px solid #e2dddd!important;
  color: #333!important;
  padding: 6px 19px!important;
  text-decoration: none;
  border-radius: 4px;
  margin-left: -14%;
}#table-styles .heading span:nth-child(3) {
  border: 1px solid #e2dddd!important;
  color: #333!important;
  padding: 6px 19px!important;
  text-decoration: none;
  border-radius: 4px;
  margin-left: 10px;
}

div#createview-add-remove div {
  padding: 130% 0;
}

div#createview-add-remove div p svg {
  font-size: 19px;
  color: #333;
  cursor: pointer;
}
div#createview-add-remove div p { 
  color: #333;
}
.selectedFields{
  padding: 8px;
}





div.alphabet {
  display: table;
  width: 100%;
  margin-bottom: 1em;
}

div.alphabet span {
  display: table-cell;
  color: #3174c7;
  cursor: pointer;
  text-align: center;
  width: 3.5%
}

div.alphabet span:hover {
  text-decoration: underline;
}

div.alphabet span.active {
  color: black;
}

.select-container {
  position: relative;
}

.floating-labels {
  position: absolute;
  pointer-events: none;
  top: 10px;
  left: 10px;
  transition: transform 0.2s ease-out, font-size 0.2s ease-out;
}

.floating-labels.active {
  transform: translateY(-20px);
  font-size: 12px;
}

.select-filter .css-b62m3t-container {
  width: 100%;
  z-index: 9999;
}

.accounts-page.create-view {
  padding-bottom: 20%;

}


#create-table td{text-transform: capitalize;}
#create-table tr th {
  font-weight: 600!important;
  text-transform: capitalize;
}
.select-filter .css-b62m3t-container {
  width: 100%;
}
.select-filter .css-13cymwt-control{
  padding: 0px!important;
}
.filter-table .rdt_TableCol_Sortable div{text-transform: capitalize;}




/* menusas */

nav {
  background-color: #15487f;
  overflow: hidden;
  font-size: 14px;
  display: flex;
        justify-content: space-around; /* Adjust as needed */
        align-items: center; 
         
}

nav a {
  text-decoration: none;
    color: #fff;
    padding: 10px;
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

nav a:hover {
  background-color: #103358!important;
  color: #fff!important;
}
.dropdown-item {padding: 0px;font-size: 14px;}
nav a.active {
  background-color: #4CAF50;
  color: white;
} 
.edit-delete span {
  cursor: pointer;
}
input.react-datepicker-ignore-onclickoutside {
  outline: none;
}
div#dashboard .col-md-3 .bg-info:hover {
  /* background: #f7c9ac!important; */
  box-shadow: 3px 12px 12px #cbc6c6;
}
#dashboard a{text-decoration: none; color: #333;}
.dropdown-item
a.dropdown-item a {
  padding: 8px;
}
.dropdown-menu.show {
  position: absolute;
  inset: 0px auto auto 0px;
  transform: translate3d(1230.4px, 55.2px, 0px);
}
@media screen and (max-width: 600px) {
  nav a {
      float: none;
      display: block;
      width: 100%;
      text-align: left;
  }
}

.dropdown, .dropend, .dropstart, .dropup{
  position: inherit!important;
}


.col-md-2.recent-item div {
  background: #cfeef8;
    font-size: 14px;
    margin: 10px;
    padding: 4px 4px 4px 0px;
    border-radius: 2px;
}
.col-md-2.recent-item {
  width: 14.666667%;
}
.col-md-2.recent-item div p a{
  color: #000;
  cursor: pointer;
  font-size: 12px;
}
.reports h4 {
  background: #1797c0;
  margin: 6px!important;
  font-size: 14px;
  padding: 8px;
  color: #fff;
  border-radius: 8px;
  text-decoration:none!important;
}
 
.reports{
  padding-left: 10px!important;
  cursor: pointer;
  text-decoration: underline!important;
}
.css-1dimb5e-singleValue{top: 6px;position: relative;}
.select-dropdown label{top:-11px!important;position: absolute;}
.col-md-2.recent-item div p {
  padding: 2px!important;
  font-size: 13px!important;
  color: #000;
  margin-bottom: 1px;
  text-decoration: none!important;
}
.col-md-2.recent-item h4 {
  background: #1797c0;
  margin: 11px;
  font-size: 14px;
  padding: 8px;
  color: #fff; 
  border-radius: 8px;
}

.full-container .col-md-10{
  padding-left: 0px;
  width: 85.3333%;
}
.full-container .col-md-2{
  padding-right: 0px;
}
p.edit-cancel input {
  font-size: 12px!important;
}

.contact-right h4{
  width: 100%!important;
}
.contact-right .css-b62m3t-container{
  width: 100%!important;
  margin-bottom: 0px!important;
}
.textEditor {
  width: 48% !important;
  margin: 0 auto;
  margin-bottom: 20px;
}
#list_of_documents_required{
  width: 100%!important;
}
.list_of_documents_required .mb-3 {
  padding: 0px !important;
  margin: 0px !important;
}
.account-save:disabled {
  font-size: 12px !important;
  background: #e2e0e0;
  cursor: not-allowed;
}

.multiselect .form-floating>.form-select{height: 276px!important;}
.lists textarea {
  width: 100% !important;
}
.lists .form-floating{
  padding: 0!important;
  margin: 0!important;
}

/* alignment */
.textarea-half.col-md-6 {
  margin: 0 auto;
  width: 48%;
}
input,textarea{font-size: 13px!important;}
textarea.form-control {
  min-height: calc(3.5em + .75rem + 2px)!important;
  overflow: hidden;
}
.css-b62m3t-container{
  padding: 0px!important;
  margin: 0px!important;
  width: 100% !important;
  margin-bottom: 0px !important;
}
.order-section .dropDown.form-floating {
  margin-bottom: 15px !important;
  width: 96% !important;
  margin: 0 auto;
}
.post-shipment .rsw-editor {
  border: 1px solid #ddd;
  border-radius: .375rem;
  display: flex;
  flex-direction: column;
  min-height: 100px;
  overflow: hidden;
  width: 96% !important;
  margin: 0 auto;
}

