.rwood_report_generation_container {

    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

}

.rwood_report_selection_container {
    height: 100vh;
    margin-top: 80px;
}

.rwood_report_action_btn {
    margin-top: 15px;
    display: block;
    margin: 10px 100%;
}

/* doc generator */
.preview_table_drop_area {
    border: 2px dashed #ccc;
    padding: 20px;
    min-height: 100px;
    background-color: #f9f9f9;
    margin-top: 20px;
}


body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    height: 100vh;
}

.container {
    display: flex;
    width: 100%;
}

.sidebar {
    width: 20%;
    background-color: #f8f8f8;
    border-right: 1px solid #ccc;
    padding: 10px;
    box-sizing: border-box;
    overflow-y: auto;
}

.main-content {
    width: 80%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
}

.filters {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 10px;
    background-color: #f1f1f1;
    border-bottom: 1px solid #ccc;
}

.filters input,
.filters select {
    margin-left: 10px;
}

.preview {
    flex-grow: 1;
    overflow-y: auto;
}

.preview table {
    width: 100%;
    border-collapse: collapse;
}

.preview th,
.preview td {
    font-size: 12px;
    padding: 8px;
    border: 1px solid #ccc;
    text-align: left;
}

.preview th {
    font-size: 12px;
    background-color: #f2f2f2;
}

.tree {
    list-style-type: none;
    padding-left: 20px;
}

.caret {
    cursor: pointer;
    user-select: none;
}

.caret::before {
    content: "\25B6";
    color: black;
    display: inline-block;
    margin-right: 6px;
}

.caret-down::before {
    transform: rotate(90deg);
}

.nested {
    display: none;
}

.caret-down+.nested {
    display: block;
}

.selected {
    background-color: #e0e0e0;
}

.parent {
    font-weight: bold;
}

.container {
    display: flex;
    width: 100%;
    height: 100vh;
    font-family: Arial, sans-serif;
}

.sidebar {
    width: 20%;
    background-color: #f8f8f8;
    border-right: 1px solid #ccc;
    padding: 10px;
    box-sizing: border-box;
    overflow-y: auto;
}

.main-content {
    width: 80%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
}

.filters {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #f1f1f1;
    border-bottom: 1px solid #ccc;
}

.filters input,
.filters select {
    margin-left: 10px;
}

.preview {
    flex-grow: 1;
    overflow-y: auto;
}

.preview table {
    width: 100%;
    border-collapse: collapse;
}

.preview th,
.preview td {
    padding: 8px;
    border: 1px solid #ccc;
    text-align: left;
}

.preview th {
    background-color: #f2f2f2;
}

.tree {
    list-style-type: none;
    padding-left: 20px;
}

.caret {
    cursor: pointer;
    user-select: none;
}

.caret::before {
    content: "\25B6";
    color: black;
    display: inline-block;
    margin-right: 6px;
}

.caret-down::before {
    transform: rotate(90deg);
}

.nested {
    display: none;
}

.caret-down+.nested {
    display: block;
}

.selected {
    background-color: #e0e0e0;
}

.parent {
    font-weight: bold;
}



ul.tree,
ul.tree ul {
    list-style-type: none;
    padding-left: 20px;
    margin: 0;
}

ul.tree ul {
    display: none;
    padding-left: 20px;
    margin-top: 10px;
}

.caret {
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    padding: 5px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.caret:hover {
    background-color: #f0f0f0;
}

.parent::before {
    content: "▶";
    color: #555;
    display: inline-block;
    margin-right: 10px;
    transition: transform 0.3s;
}

.caret-down::before {
    transform: rotate(90deg);
}

.selected {
    background-color: #d0e0fb;
    /* Light blue background */
}

.caret-down+.nested {
    display: block;
    animation: fadeIn 0.3s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}




.filter-options {
    display: flex;
    align-items: center;
    padding: 0 5px;
}

.modal-buttons {
    margin: 0 8px;
}

.filter_condition_p {
    color: rgb(10, 38, 130);
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
    margin: 10px;

}